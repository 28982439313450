
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {getOneperson } from '../../api/person.api'

import './personpeaple.scss';






const Ppersonpeaple = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  	const [loftyendpoint_65b96a84ddfeab4572d75e09, setLoftyEndpoint_65b96a84ddfeab4572d75e09] = useState({});
	
  useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_65b96a84ddfeab4572d75e09 = await getOneperson({ _id: match.params.id, token: loftytoken || 'asdsa' });
			setLoftyEndpoint_65b96a84ddfeab4572d75e09(loftyres_65b96a84ddfeab4572d75e09.data);
		}
		loftyHandleData();
	}, []);

  
  
  
  

  
  return (
    <div id="id65bac8eab0184704f908f0cc" >

      <div id="iu93" className="">
      
      <div id="i9t9" className="">
      
      <img
        className=""
        id="iv25"
        src="https://assetsprojects.s3.amazonaws.com/406raciltou54t5.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="ik3f" className="">
      
        <div id="idnx2" className="">
        
      <div id="io19w" className="">
      
        <div className="" id="i7add">
          
          <div className="" id="ihvdk">
            {loftyendpoint_65b96a84ddfeab4572d75e09.nombre}
          </div>
          
        </div>
        
      </div>
      
      <div id="imjdv" className="">
      
      <div id="idr7h" className="">
      
      <div id="inazy8" className="">
      
      <div id="ibynqd" className="">
      
      </div>
      
      </div>
      
      <div id="ixal4t" className="">
      
      <p className="" id="i4umna">
        <span>INFORMACIÓN</span>
      </p>
      
      <div id="i8hqv4" className="">
      
      </div>
      
      <div id="iq7y6x" className="">
      
      <div id="iwgw87" className="">
      
      <img
        className=""
        id="itqo2"
        src="https://assetsprojects.s3.amazonaws.com/406racilu48snh0.png"
        alt="undefined"
      />
      
      <p className="label-info " id="idmq5">
        <span>DNI</span>
      </p>
      
      </div>
      
      <div id="iz7swt" className="">
      
        <div className="" id="i7221">
          
          <div className="" id="iq09i">
            {loftyendpoint_65b96a84ddfeab4572d75e09.dni}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="ii4hqk" className="">
      
      <div id="ib12uf" className="">
      
      <img
        className=""
        id="i2rkvv"
        src="https://assetsprojects.s3.amazonaws.com/406racilu49o3te.png"
        alt="undefined"
      />
      
      <p className="label-info " id="ijp4l">
        
      <b className="" id="">
        
      <span className="" id="if42iu">
        <span>Tipo de sangre</span>
      </span>
      
      </b>
      
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="iz71jx" className="">
      
        <div className="info-right " id="iecd9e">
          
          <div className="" id="i67g7">
            {loftyendpoint_65b96a84ddfeab4572d75e09.gruposanguineo}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="iaqqrh" className="">
      
      <div id="isgtre" className="">
      
      <img
        className=""
        id="i6sdgg"
        src="https://assetsprojects.s3.amazonaws.com/406racilu49s3d5.png"
        alt="undefined"
      />
      
      <p className="label-info " id="iqmamv">
        <span>Nombre de Contac.</span>
      </p>
      
      </div>
      
      <div id="im4n82" className="">
      
        <div className="info-right " id="iet0w">
          
          <div className="" id="ik2pq">
            {loftyendpoint_65b96a84ddfeab4572d75e09.nombrecontacto}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="i37kur" className="">
      
      <div id="ij1weq" className="">
      
      <img
        className=""
        id="ieu15h"
        src="https://assetsprojects.s3.amazonaws.com/406racilu494h86.png"
        alt="undefined"
      />
      
      <p className="label-info " id="i9nzg">
        <span>Teléfono</span>
      </p>
      
      </div>
      
      <div id="ihc2uq" className="">
      
        <div className="info-right " id="iuye2">
          
          <div className="" id="">
            {loftyendpoint_65b96a84ddfeab4572d75e09.telefonocontacto}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="i32ivv" className="">
      
      <div id="is2f19" className="">
      
      <img
        className=""
        id="it7cgf"
        src="https://assetsprojects.s3.amazonaws.com/406racilu49qge7.png"
        alt="undefined"
      />
      
      <p className="label-info " id="izgbk">
        <span>Tipo de Seguro</span>
      </p>
      
      </div>
      
      <div id="iy0h34" className="">
      
        <div className="" id="iujt7v">
          
          <div className="" id="">
            {loftyendpoint_65b96a84ddfeab4572d75e09.tipodeseguro}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="ildcbc" className="">
      
      <div id="i2hras" className="">
      
      <img
        className=""
        id="i9gs7w"
        src="https://assetsprojects.s3.amazonaws.com/406racilu495cub.png"
        alt="undefined"
      />
      
      <p className="label-info " id="iiam57">
        <span>Alergias</span>
      </p>
      
      </div>
      
      <div id="iabnw2" className="">
      
        <div className="" id="ietxyk">
          
          <div className="" id="iczel">
            {loftyendpoint_65b96a84ddfeab4572d75e09.alergia}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="iv35fm" className="">
      
      <div id="ia1wjh" className="">
      
      <img
        className=""
        id="itv2s9"
        src="https://assetsprojects.s3.amazonaws.com/406racilu49wuop.png"
        alt="undefined"
      />
      
      <p className="label-info " id="ie8e8">
        <span>Medicamentos</span>
      </p>
      
      </div>
      
      <div id="i8wog4" className="">
      
        <div className="" id="ilysj6">
          
          <div className="" id="">
            {loftyendpoint_65b96a84ddfeab4572d75e09.medicamentos}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="ikgjog" className="">
      
      <div id="izezfh" className="">
      
      <img
        className=""
        id="ifesc1"
        src="https://assetsprojects.s3.amazonaws.com/406racilu495u42.png"
        alt="undefined"
      />
      
      <p className="label-info " id="ik7jk">
        <span>Otra información</span>
      </p>
      
      </div>
      
      <div id="iehi3l" className="">
      
        <div className="" id="iog8hl">
          
          <div className="" id="i2a9s">
            {loftyendpoint_65b96a84ddfeab4572d75e09.otrainformacion}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ijjmdi" className="">
      
      <div id="iccer" className="">
      
        <img
          className=""
          id="ib6l5"
          alt=""
          src={loftyendpoint_65b96a84ddfeab4572d75e09.loftyUrl}
        />
        
      </div>
      
      </div>
      
      </div>
      
        </div>
        
      </div>
      
      <div id="i71w" className="">
      
      <div id="ipm36" className="">
      
      <p className="" id="i81dl">
        <span>Siguenos en</span>
      </p>
      
        <a
          target=""
          className=""
          id="is6nay"
          href="https://www.facebook.com/profile.php?id=100089754458312"
        >
          
      <img
        className=""
        id="ijrwk"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexcclub.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i85cif"
          href="https://www.instagram.com/mismemoriasqr/"
        >
          
      <img
        className=""
        id="iprxd"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexcehfe.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i3tvb9"
          href="https://api.whatsapp.com/send/?phone=50489196588"
        >
          
      <img
        className=""
        id="i5n3w"
        src="https://assetsprojects.s3.amazonaws.com/406raciltoud35y.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Ppersonpeaple);
  