
import React from 'react';
import {createReactEditorJS} from 'react-editor-js';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import ImageTool from '@editorjs/image';

const ReactEditorJS = createReactEditorJS();

function LoftyEditorJsReadOnly({
  defaultValue, id, className,
}) {
  const EDITOR_JS_TOOLS = {
    paragraph: Paragraph,
    table: Table,
    list: List,
    image: {
      class: ImageTool,
    },
  };
  return (
    <div
      id={id}
      className={className}
    >
	{
		defaultValue ? (
      <ReactEditorJS
        style={{
          width: '100%',
        }}
        defaultValue={{
          time: new Date(),
          blocks: JSON.parse(defaultValue),
        }}
        readOnly
        tools={EDITOR_JS_TOOLS}
      />
	) : <></>
	}
    </div>
  );
}

export default LoftyEditorJsReadOnly;
    
    
