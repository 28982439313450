
import axios from '../config/axios';

export const url = '/api/articlescollection'


export const getOnearticlescollection = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}//one/basic/6453e037e474ae33c68ff818/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      
export const createarticlescollection = ({
  token,
	titlearticle,
	infoarticledata,
	codigo,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && codigo ) {

    axios.post(`${url}//create/basic/6453e039e474ae33c68ff825`, {
		titlearticle,
		infoarticledata,
		codigo,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!codigo) {
		reject({
			status: 'error',
			info: 'codigo no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      
export const getAllarticlescollection = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}//list/basic/6453e9b6e474ae33c68ff911`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      

