
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {getOnepets } from '../../api/pets.api'

import './paginamascota.scss';






const Ppaginamascota = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  	const [loftyendpoint_65b9668bddfeab4572d75c6f, setLoftyEndpoint_65b9668bddfeab4572d75c6f] = useState({});
	
  useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_65b9668bddfeab4572d75c6f = await getOnepets({ _id: match.params.id, token: loftytoken || 'asdsa' });
			setLoftyEndpoint_65b9668bddfeab4572d75c6f(loftyres_65b9668bddfeab4572d75c6f.data);
		}
		loftyHandleData();
	}, []);

  
  
  
  

  
  return (
    <div id="id65b96ac2b0184704f908ea08" >

      <div id="iu93" className="">
      
      <div id="i9t9" className="">
      
      <img
        className=""
        id="iv25"
        src="https://assetsprojects.s3.amazonaws.com/406raciltou54t5.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="ik3f" className="">
      
      <div id="ilea1" className="">
      
      </div>
      
        <div id="intwl" className="">
        
      <div id="iicz1" className="info-container ">
      
      <div id="ipyfy" className="">
      
      <div id="iccer" className="">
      
        <img
          className=""
          id="ib6l5"
          alt=""
          src={loftyendpoint_65b9668bddfeab4572d75c6f.loftyUrl}
        />
        
      </div>
      
      </div>
      
      <div id="icusm" className="">
      
      <div id="ie67s" className="">
      
      <img
        className=""
        id="i2rtp"
        src="https://assetsprojects.s3.amazonaws.com/406racilsmd0c17.png"
        alt="undefined"
      />
      
        <div className="" id="ioc6c">
          
          <div className="" id="imi8i">
            {loftyendpoint_65b9668bddfeab4572d75c6f.name}
          </div>
          
        </div>
        
      </div>
      
      <div id="ihpxa" className="">
      
      </div>
      
        <div className="" id="ij9dv">
          
          <div className="" id="icpg7">
            {loftyendpoint_65b9668bddfeab4572d75c6f.Mensaje}
          </div>
          
        </div>
        
      <div id="i4w1f" className="">
      
      <div id="imx2n" className="card ">
      
      <div id="ibfwr" className="">
      
      <img
        className=""
        id="iq68p"
        src="https://assetsprojects.s3.amazonaws.com/406racilsmd786m.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="idmq5">
        <span>Raza</span>
      </p>
      
        <div className="" id="ivxnv">
          
          <div className="" id="ift4x">
            {loftyendpoint_65b9668bddfeab4572d75c6f.raza}
          </div>
          
        </div>
        
      </div>
      
      <div id="ifzb45" className="card ">
      
      <div id="iehml" className="">
      
      <img
        className=""
        id="i1839"
        src="https://assetsprojects.s3.amazonaws.com/406racilsmd9bp9.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="i34gxp">
        <span>Esterilizado</span>
      </p>
      
        <div className="" id="i852ug">
          
          <div className="" id="">
            {loftyendpoint_65b9668bddfeab4572d75c6f.esterilizado2}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="i407jf" className="">
      
      <div id="ipgxh7" className="">
      
      <div id="i8y6nb" className="">
      
      <div id="ifki22" className="">
      
      <img
        className=""
        id="it9lur"
        src="https://assetsprojects.s3.amazonaws.com/406racilsmdjp3a.png"
        alt="undefined"
      />
      
      <p className="" id="i9nzg">
        <span>Teléfono</span>
      </p>
      
      </div>
      
      <div id="iy6dg4" className="">
      
        <div className="" id="if1sc">
          
          <div className="" id="iaacb">
            {loftyendpoint_65b9668bddfeab4572d75c6f.telephone}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="imrxie" className="">
      
      </div>
      
      </div>
      
      <div id="iov06g" className="">
      
      <div id="iluzgb" className="">
      
      <div id="id5mtg" className="">
      
      <img
        className=""
        id="ikm15g"
        src="https://assetsprojects.s3.amazonaws.com/406racilsmdmz0q.png"
        alt="undefined"
      />
      
      <p className="" id="iqmamv">
        <span>Sexo</span>
      </p>
      
      </div>
      
      <div id="impe8a" className="">
      
        <div className="" id="i7uyk">
          
          <div className="" id="">
            {loftyendpoint_65b9668bddfeab4572d75c6f.sex}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="i6d9g4" className="">
      
      </div>
      
      </div>
      
      <div id="in1j0t" className="">
      
      <div id="i954jg" className="">
      
      <div id="i8dcci" className="">
      
      <img
        className=""
        id="i0dhkj"
        src="https://assetsprojects.s3.amazonaws.com/406z02ilux1brnw.png"
        alt="undefined"
      />
      
      <p className="" id="iiam57">
        <span>Veterinaria</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="i6linh" className="">
      
        <div className="" id="iftpdx">
          
          <div className="" id="">
            {loftyendpoint_65b9668bddfeab4572d75c6f.Veterinaria}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="i09z9i" className="">
      
      </div>
      
      </div>
      
      <div id="ihsowt" className="">
      
      <div id="ilpikj" className="">
      
      <div id="i2mu8h" className="">
      
      <img
        className=""
        id="isw1b6"
        src="https://assetsprojects.s3.amazonaws.com/406racilsmdnxcf.png"
        alt="undefined"
      />
      
      <p className="" id="ie8e8">
        <span>Enfermedades</span>
      </p>
      
      </div>
      
      <div id="i1lrxx" className="">
      
        <div className="" id="idg8i">
          
          <div className="" id="">
            {loftyendpoint_65b9668bddfeab4572d75c6f.enfermedades}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
      <div id="i28g5l" className="">
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
        </div>
        
      </div>
      
      <div id="i71w" className="">
      
      <div id="ipm36" className="">
      
      <p className="" id="i81dl">
        <span>Siguenos en</span>
      </p>
      
        <a
          target=""
          className=""
          id="iq8xwj"
          href="https://www.facebook.com/profile.php?id=100089754458312"
        >
          
      <img
        className=""
        id="ijrwk"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexcclub.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="ii52an"
          href="https://www.instagram.com/mismemoriasqr/"
        >
          
      <img
        className=""
        id="iprxd"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexcehfe.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="il4fz3"
          href="https://api.whatsapp.com/send/?phone=50489196588"
        >
          
      <img
        className=""
        id="i5n3w"
        src="https://assetsprojects.s3.amazonaws.com/406raciltoud35y.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Ppaginamascota);
  