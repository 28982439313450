
import React from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './landing.scss';






const Planding = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  
  
  

  
  return (
    <div id="id640624da9b0ba7dbe6f18d40" >

      <div id="ixok" className="">
      
      <div id="ib79" className="">
      
      <div id="ihi2" className="">
      
      <img
        className=""
        id="ih7cj"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilex44c50.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="if8si" className="">
      
      <p className="" id="i4su3">
        <span>Conócenos</span>
      </p>
      
      <p className="" id="i5c9y">
        
      <a
        target="_blank"
        className="link "
        id=""
        href="https://api.whatsapp.com/send?phone=50488196588&text=Gracias%20por%20comunicarte,%20en%20qu%C3%A9%20podemos%20servirle."
        type=""
      >
        <span>Contáctanos</span>
      </a>
      
      </p>
      
      <p className="" id="iw65y">
        <span>Nuestros productos</span>
      </p>
      
      </div>
      
      <div id="iuugo" className="">
      
      </div>
      
      </div>
      
      <div id="ikqg" className="">
      
      <div id="i2u8k" className="">
      
      <img
        className=""
        id="isx9j"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilex4hnxv.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i5re6" className="">
      
      <div id="ibhjv" className="">
      
      <img
        className=""
        id="i5fiv"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilex4gotg.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ivyke" className="">
      
        <a
          target=""
          className=""
          id="izjo5n"
          href="https://api.whatsapp.com/send?phone=50488196588&text=Gracias%20por%20comunicarte,%20en%20qu%C3%A9%20podemos%20servirle."
        >
          
      <button
        type=""
        name=""
        id="idbjs"
        className="standard_button "
        
      >
      
      <div id="injeo" className="">
      
      <a
        target="_blank"
        className="link "
        id=""
        href="https://api.whatsapp.com/send?phone=50489196588&text=Gracias%20por%20comunicarte,%20en%20qu%C3%A9%20podemos%20servirle."
        type=""
      >
        <span>Contáctanos</span>
      </a>
      
      </div>
      
      </button>
      
        </a>
        
      </div>
      
      <div id="i8sotx" className="">
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iq3do" className="">
      
      <div id="in2ae" className="">
      
      <div id="i45wn" className="">
      
      </div>
      
      <p className="" id="ii4t8">
        <span>Nuestras categorías</span>
      </p>
      
      </div>
      
      <div id="ip88d" className="">
      
      <div id="izxrx4" className="gjs-row ">
      
      <div id="ihvlrh" className="gjs-cell ">
      
      <div id="ipvdg" className="">
      
      <img
        className=""
        id="ihd4r"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexa0h4q.png"
        alt="undefined"
      />
      
      <p className="" id="ib79w">
        <span>Servicios</span>
      </p>
      
      <div id="i10xk" className="">
      
      <p className="" id="ine7z">
        
    <div id="" className="">
    
      <span className="" id="imj4l9">
        <span>Códigos para  tu negocio, tarjetas de presentación</span>
      </span>
      
      <span className="" id="iuk43f">
        <span>, ventas de autos, casas y más.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="ibsfh" className="">
      
      <img
        className=""
        id="i1uwi"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexa0h4q.png"
        alt="undefined"
      />
      
      <p className="" id="igujy">
        <span>Mascotas</span>
      </p>
      
      <div id="izjpn" className="">
      
      <p className="" id="i3o8v">
        
    <div id="" className="">
    
      <span className="" id="icygrk">
        <span>Identificamos a tus mascotas con collares QR con fotos y videos para que nunca se pierdan.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="in9wpv" className="gjs-cell ">
      
      <div id="i9bv1i" className="">
      
      <img
        className=""
        id="iw034b"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexa0h4q.png"
        alt="undefined"
      />
      
      <p className="" id="ilv6al">
        <span>Infraestructura</span>
      </p>
      
      <div id="i6013h" className="">
      
      <p className="" id="iue294">
        
    <div id="" className="">
    
      <span className="" id="ijzwch">
        <span>Iglesias, parques, sitios turísticos y todo tipo de obras que necesitan ser conocidas.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="iweccj" className="">
      
      <img
        className=""
        id="iw08nb"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexa0h4q.png"
        alt="undefined"
      />
      
      <p className="" id="iezfll">
        <span>Salud</span>
      </p>
      
      <div id="iw65sn" className="">
      
      <p className="" id="i4rhsy">
        
    <div id="" className="">
    
      <span className="" id="iofsip">
        <span>Porque los amamos, tenemos códigos para los más vulnerables y para uso de servicios médicos.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ieav8" className="">
      
      <div id="irqsj" className="">
      
      <p className="" id="imoryu">
        <span>Te brindamos el servicios de elaboración de códigos en distintos materiales y alojamiento en nuestra robusta plataforma.  </span>
      </p>
      
      <p className="" id="ir611b">
        <span>El primer sitio público donde puedes guardar y modificar tu información</span>
      </p>
      
      <div id="ifqmpe" className="">
      
      <button
        type=""
        name=""
        id="id68ra"
        className="standard_button "
        
      >
      
      <div id="iah7nc" className="">
      
      <a
        target=""
        className="link "
        id=""
        href=""
        type=""
      >
        <span>Lo que dicen de nosotros</span>
      </a>
      
      </div>
      
      </button>
      
      <button
        type=""
        name=""
        id="iz3vlr"
        className="standard_button "
        
      >
      
      <div id="ifvqt7" className="">
      
      <a
        target=""
        className="link "
        id="i5ti3l"
        href=""
        type=""
      >
        
      </a>
      
      <a
        target=""
        className="link "
        id="i5hgof"
        href=""
        type=""
      >
        
      </a>
      
      <a
        target=""
        className="link "
        id="iiaopl"
        href=""
        type=""
      >
        
      <b className="" id="">
        
      </b>
      
      </a>
      
      <b className="" id="">
        
      <a
        target=""
        className="link "
        id="ijymlm"
        href=""
        type=""
      >
        <span>Noticia relacionadas</span>
      </a>
      
      </b>
      
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iykm3" className="">
      
      <div id="ir5u3o" className="">
      
      <div id="ifk61d" className="">
      
      <div id="ifc09v" className="">
      
      </div>
      
      <div id="ir9pbl" className="">
      
      <p className="" id="i1n1aa">
        <span>Quiénes somos?</span>
      </p>
      
      <p className="" id="ifql3q">
        
    <div id="" className="">
    
      <span className="" id="iy2llw">
        <span>Memorias
QR es la primera plataforma pública para elaborar, alojar tus códigos, editarlos y utilizarlos en donde
tú quieras.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="icd57u" className="">
      
      <div id="imspvg" className="">
      
      </div>
      
      <div id="iu3bfq" className="">
      
      <p className="" id="im0b0l">
        <span>Dónde usarlos?</span>
      </p>
      
      <p className="" id="i9sc1f">
        
    <div id="" className="">
    
      <span className="" id="icya09">
        <span>Puedes usar nuestros códigos en lápidas fúnebres, placas, collares de mascotas, bienes raíces, sitios históricos, inauguración de obras, venta de autos y más.</span>
      </span>
      
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="it81hu" className="">
      
      </div>
      
      </div>
      
      <div id="idq7gj" className="">
      
      <div id="i6nxf3" className="">
      
      <img
        className=""
        id="iwa7km"
        src="https://assetsprojects.s3.amazonaws.com/406racilu4b9rw6.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iibeuk" className="">
      
      <p className="" id="i0x9r4">
        
    <div id="" className="">
    <span>Nuestros productos más demandados son los códigos QR tipo collares para mascotas, placas fúnebres, placas de reconocimiento, cruces, nichos, retrateras con información de nuestro pariente difunto, tarjetas de presentación, entre otros.</span>
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      <div id="ig2ifr" className="">
      
      <p className="" id="it7eos">
        
    <div id="" className="">
    <span>En Memorias QR estamos a la orden para atenderte de forma </span>
    </div>
    
    <div id="" className="">
    <span>personalizada y elaborar tus códigos en distintos materiales y tamaños </span>
    </div>
    
    <div id="" className="">
    <span>para que puedas guardar tu información y modificarla en nuestra plataforma.</span>
    </div>
    
      </p>
      
      </div>
      
      <div id="in7dtt" className="">
      
      <div id="immkzl" className="">
      
      <img
        className=""
        id="imy4ii"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexc126t.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="imv0cf" className="">
      
      <div id="iih7tk" className="">
      
      <p className="" id="i8h54l">
        
    <div id="" className="">
    <span>Con nosotros podrás modificar la información en cualquier momento.</span>
    </div>
    
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i2oebe" className="">
      
      <div id="ircepv" className="">
      
      <div id="i8othv" className="">
      
      <p className="" id="iev7tp">
        <span>Siguenos en</span>
      </p>
      
        <a
          target=""
          className=""
          id="iz9w5a"
          href="https://www.facebook.com/profile.php?id=100089754458312"
        >
          
      <img
        className=""
        id="iczgtf"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexcclub.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i3ox1c"
          href="https://www.instagram.com/mismemoriasqr/"
        >
          
      <img
        className=""
        id="ictlkj"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexcehfe.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="ikrgh9"
          href="https://api.whatsapp.com/send?phone=50488196588&text=Gracias%20por%20comunicarte,%20en%20qu%C3%A9%20podemos%20servirle."
        >
          
      <img
        className=""
        id="ilzmk2"
        src="https://assetsprojects.s3.amazonaws.com/406raciltoud35y.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Planding);
  